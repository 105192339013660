import * as React from "react";
import { withStores } from "stores";
import { SeparatorProps, SeparatorFlexModuleResult } from "components/flexComponents/Separator/typings";
import { UitkSpacing, UitkSpacingHr } from "uitk-react-spacing";

export const Separator = withStores(
  "flexModuleModelStore",
  "context"
)((props: SeparatorProps) => {
  const { templateComponent, flexModuleModelStore } = props;

  /* istanbul ignore if */
  if (!templateComponent || !flexModuleModelStore) {
    return null;
  }

  const { metadata, config } = templateComponent;
  const { id } = metadata;
  const { view } = config;
  const model = flexModuleModelStore.getModel(id) as SeparatorFlexModuleResult;
  const { spacing } = model;

  if (view === "simple") {
    return (
      <UitkSpacing padding={{ blockstart: spacing }}>
        <div data-testid="simple-separator" />
      </UitkSpacing>
    );
  }

  return <UitkSpacingHr blockMargin={spacing} />;
});

Separator.displayName = "Separator";

export default Separator;
